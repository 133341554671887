
import { mapGetters } from "vuex";

export default {
    name: "NewsComponent",
    data: () => ({
        api: "news",
        locas: [
            {
                image: "/assets/new-license-img.jpg",
                title: "LOGA's New",
                subtitle: "License Registration",
                blur_text: "license of all iGaming products --",
                text: "Online Casinos, Supports Betting, Poker, Lotteries, and Bingo!",
                button_text: "Apply for new license",
                button_color: "error",
            },
            {
                image: "/assets/logo-helpdesk.jpg",
                title: "LOGA's Helpdesk",
                subtitle: "How to License Register",
                blur_text: "Interested parties can contact by",
                text: "Online Client Support, Phone call, Write an Email.",
                button_text: "Contact us now",
                button_color: "black",
            },
        ],
    }),
    computed: {
        ...mapGetters({
            searchText: "pages/searchText",
            news: "news/news",
            loading: "news/loading",
        }),
    },
    methods: {
        getUTubeId(linkUrl) {
            if (!linkUrl) return "";
            const yurl = linkUrl.split("/watch?v=")[1];
            return yurl;
        },
    },
};
