
export default {
    name: "Index",

    data() {
        return {
            checkWidth: true,
        };
    },
    head: {
        title: "Home",
        htmlAttrs: {
            lang: "en",
        },
        meta: [
            { charset: "utf-8" },
            {
                name: "viewport",
                content: "width=device-width, initial-scale=1",
            },
            {
                hid: "description",
                name: "description",
                content: `LOGC home page is reprecent about the latest article, news and announcement of Laos off-shore gaming control corporation`,
            },
            { name: "format-detection", content: "telephone=no" },
        ],
    },
    mounted() {
        this.$store.dispatch("news/getNews", 1);

        const sWidth = window.innerWidth;
        this.checkWidth = sWidth >= 1265;
        window.addEventListener("resize", () => {
            const sWidth = window.innerWidth;
            this.checkWidth = sWidth >= 1265;
        });
    },
    methods: {},
};
