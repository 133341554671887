
import { mapGetters } from "vuex";
export default {
    name: "NewsComponent",
    data: () => ({
        api: "news",
    }),
    computed: {
        ...mapGetters({
            searchText: "pages/searchText",
            news: "news/news",
            loading: "news/loading",
        }),
    },
    methods: {},
};
